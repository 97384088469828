@import "responsive";
@import "variables";

@mixin bottom-border {
  border-bottom: 1px solid $grey-6;
}

@mixin top-border {
  border-top: 1px solid $grey-6;
}

.page {
  background: white;
  height: 100%;
  min-height: 100vh;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  padding: 0px 20px 0px 20px;

  @include md {
    padding: 30px 0px 30px 0px;
  }

  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 143.52%;
    color: $grey-1;
    margin: 24px 0px 24px 0px;
  }

  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 143.52%;
    color: $grey-1;
    margin: 0px 0px 16px 0px;
  }

  p {
    color: $grey-3;
  }

  .bold {
    font-weight: bold;
    color: $grey-3;
  }
}

.line-block {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 32px 0px;
  margin: 0px 0px 20px 0px;
  border-bottom: 1px solid #e4e5e7;
}
.success-message {
  height: min-content;
  display: block;
  text-align: center;

  @include md {
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.link {
  color: $primary-blue;
  font-size: 14px;
  font-weight: 500;
}

.icon-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  @include md {
    margin-bottom: 60px;
  }
}
